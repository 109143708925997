<template>
<Modal
  :title="title"
  :submitText="submitText"
  :animate="confirmAnimating"
  class="prompt-modal"
  @submit="$emit('submit')"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="prompt-content">
      {{ description }}
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';

export default {
  name: 'prompt-modal',
  components: { Modal },
  props: {
    submitText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    confirmAnimating: Boolean,
  },
  destroyed() {
    document.body.classList.remove('noscroll');
  },
};
</script>

<style lang="scss">
@import 'general';

.prompt-modal.modal-outer .modal-inner {
  max-width: 420px;
  .prompt-content {
    box-sizing: border-box;
    padding: 0 0 40px;
    a {
      text-decoration: underline;
    }
  }
}

</style>
