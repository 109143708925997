<template>
<div class="p-reward-card">
  <div class="reward-image" :style="{ backgroundImage: `url(${imageUrl})`}" />
  <div class="reward-content">
    <div class="reward-content-text">
      <h4 class="title">
        {{ reward.title }}
        <div v-if="showPrice" class="reward-price">
          {{ rewardPrice }}
        </div>
      </h4>
    </div>
    <div class="p_small description">
      {{ reward.description }}
    </div>
    <div class="reward-delivery">
      <div>
        <div v-if="estimatedDelivery" class="label">
          {{ $t('rewards.estimated_delivery') }}
        </div>
        {{ estimatedDelivery }}
      </div>
      <div v-if="reward.needs_shipping">
        <div class="label shipping">
          {{ $t('rewards.ships_to') }}
        </div>
        {{ countriesText }}
      </div>
    </div>
    <div v-if="reward.is_donation" class="reward-pledging">
      <TitledInput
        v-model="donationAmount"
        :title="$t('rewards.donation_amount')"
        placeholder=" "
        :hasIcon="true"
        :isDisabled="edit"
      />
    </div>
    <TitledSelect
      v-else-if="showVariants"
      :value="selectedVariant"
      :options="orderedVariants"
      label="name"
      placeholder=""
      @input="onSelectVariant"
    />
  </div>
  <div class="limitation">
    <span>{{ $tc('rewards.backers', reward.total_backers) }}</span>
    <span v-if="showBackerLimit">
      {{ $tc('rewards.backers_left', (reward.backer_limit - reward.total_backers)) }}
    </span>
  </div>
  <button
    v-if="edit"
    class="reward-action-edit"
    @click="$emit('reward-edit', reward)"
  >
    {{ buttonText }}
  </button>
  <div v-else-if="!edit && projectActive" class="reward-action">
    <button
      :class="`btn-reward-action btn-reward-action-${status} ${isAdded ? 'added' : ''}`"
      :disabled="isSoldOut"
      @click="onClickButton"
    >
      <span>{{ buttonText }}</span>
      <Plus color="white" class="plus" />
    </button>
  </div>
  <AccountRequiredModal
    v-if="showAccountRequired"
    @close-modal="showAccountRequired = false"
  />
</div>
</template>

<script>
import { fromUnixTime, isSameYear, format } from 'date-fns';
import TitledInput from '@/components/widget/TitledInput';
import TitledSelect from '@/components/widget/TitledSelect';
import AccountRequiredModal from '@/components/widget/AccountRequiredModal';
import Plus from '@/components/svg/Plus';

import { CART_VARIANT_ADJUST } from '@/store/actions';
import { RewardStatus } from '@/utils/constants';
import { objectIdSort } from '@/utils';
import { currencyDisplay } from '@/utils/objectUtils';

export default {
  name: 'reward-card',
  components: {
    TitledInput,
    TitledSelect,
    AccountRequiredModal,
    Plus,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    projectActive: {
      type: Boolean,
      default: false,
    },
    reward: {
      type: Object,
      default: null,
    },
    rewardsKey: {
      type: String,
      default: 'rewards',
    },
    currency: {
      type: String,
      default: 'USD',
    },
    status: {
      type: String,
      default: RewardStatus.ADD,
      validator(value) {
        return Object.values(RewardStatus).indexOf(value) !== -1;
      },
    },
    edit: Boolean,
  },
  data() {
    return {
      RewardStatus,
      selectedVariantId: null,
      donationAmount: '',
      isAdded: false,
      showAccountRequired: false,
    };
  },
  computed: {
    previewMode() {
      return this.$store.state.user.previewMode;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
    selectedVariant() {
      if(!this.reward) {
        return null;
      }
      const { variants } = this.reward;
      return variants.find(v => v.id === this.selectedVariantId)
        || variants.find(v => v.id === this.reward.variants_default)
        || variants[0];
    },
    imageUrl() {
      const { selectedVariant } = this;
      if(selectedVariant && selectedVariant.image.url) {
        return selectedVariant.image.url;
      }
      const { image } = this.reward.variants[0];
      if(image && image.url) {
        return image.url;
      }
      return require('@/static/img/reward_default.png'); // eslint-disable-line
    },
    showPrice() {
      const { name } = this.selectedVariant;
      if(name && name.startsWith('$')) {
        return false;
      }
      return !this.reward.is_donation;
    },
    rewardPrice() {
      const variantPrice = this.selectedVariant.price_usd;
      return `${currencyDisplay(this.currency)}${(variantPrice / 100).toLocaleString()}`;
    },
    buttonText() {
      if(this.edit) {
        return this.$t(`${this.rewardsKey}.buttons.edit`);
      }
      if(this.isAdded) {
        if(this.reward.is_donation) {
          return this.$t('rewards.buttons.donation_added');
        }
        return this.$t('rewards.buttons.added');
      }
      return this.$t(`${this.rewardsKey}.buttons`)[this.status];
    },
    showBackerLimit() {
      return this.reward && this.reward.backer_limit > 0;
    },
    showVariants() {
      return this.reward.variants.length > 1;
    },
    orderedVariants() {
      const { variants, variants_order: order } = this.reward;
      return objectIdSort(variants, order);
    },
    estimatedDelivery() {
      if(this.reward && this.reward.delivery_time) {
        const time = fromUnixTime((this.reward.delivery_time || 0));
        let dateFormat = 'MMMM yyyy';
        if(isSameYear(new Date(), time)) {
          dateFormat = 'MMMM';
        }
        return format(time, dateFormat);
      }
      return null;
    },
    isSoldOut() {
      return this.status === RewardStatus.SOLD;
    },
    countriesText() {
      const countries = Object.keys(this.reward.shipping_cost);
      if('*' in this.reward.shipping_cost) {
        return this.$t('rewards.everywhere');
      }
      if(countries.length === 1) {
        return countries[0];
      }
      if(countries.length === 2) {
        return `${countries[0]} and ${countries[1]}`;
      }
      if(countries.length === 3) {
        return `${countries[0]}, ${countries[1]} and ${countries[2]}`;
      }
      return this.$tc('rewards.countries', countries.length);
    },
  },
  methods: {
    onClickButton() {
      if(this.isAdded || this.previewMode) {
        return;
      }
      if(!this.authenticated) {
        this.showAccountRequired = true;
        return;
      }
      const {
        projectId,
        reward,
        status,
        donationAmount,
        selectedVariant,
      } = this;
      if(status === RewardStatus.ADD) {
        const cart = this.$store.getters.shoppingCart(this.projectId);
        const product = (cart.products || {})[selectedVariant.id];
        const quantity = product ? (product.quantity + 1) : 1;

        this.$store.dispatch(CART_VARIANT_ADJUST, {
          projectId,
          variantId: selectedVariant.id,
          newQuantity: reward.is_donation ? parseInt(donationAmount) * 100 : quantity,
        });
        this.$emit('reward-add');
        this.isAdded = true;
        setTimeout(this.resetStatus, 1800);
      }
    },
    resetStatus() {
      this.isAdded = false;
    },
    onSelectVariant(variant) {
      this.selectedVariantId = variant.id;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.p-reward-card {
  display: flex;
  flex-direction: column;
  width: 292px;
  border: 1px solid rgba(216, 216, 216, 0.5);
  .reward-image {
    min-height: 205px;
    background-position: center;
    background-size: cover;
  }
  .reward-content {
    padding: 20px 16px 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .reward-content-text {
      overflow: hidden;
      .title {
        display: flex;
        justify-content: space-between;
        .reward-price {
          display: inline-block;
          margin-left: 4px;
          flex-shrink: 0;
        }
      }
    }
    .description {
      color: $black-med;
      min-height: 48px;
      margin-bottom: 16px;
      width: 100%;
    }
    .p-select-wrap {
      margin: 0;
      margin-top: 8px;
    }
    .reward-delivery {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      > div {
        @include h5;
        width: 50%;
      }
      .label {
        @include subheading;
        color: $grey-medium;
        line-height: 1;
        font-weight: 600;
      }
    }
  }
  .limitation {
    @include p_info;
    color: $black-light50;
    padding: 8px 16px;
  }
  .reward-action-edit {
    @include button($white, large);
    @include button_small_text;
    color: $main-blue;
    padding-top: 8px;
    text-align: center;
    border-top: 1px solid $border-light;
    min-height: 48px;
    &:disabled {
      color: $main-blue;
      opacity: 0.2;
      background-color: $white;
    }
    &:hover:enabled {
      box-shadow: none;
    }
  }
  .reward-action {
    .btn-reward-action {
      @include button($main-blue, full);
      display: flex;
      justify-content: space-between;
      padding: 0 24px;

      &:hover:enabled {
        box-shadow: none;
      }
    }

    .btn-reward-action-add {
      transition: background-color $speed3 ease-in-out;

      svg {
        transition: opacity $speed3 ease-in-out;
      }
      &.added,
      &.added:hover:enabled,
      &.added:focus:enabled {
        background-color: $orange-med;
        transition: none;
        svg {
          opacity: 0;
          transition: none;
        }
      }
    }
    .btn-reward-action-sold {
      @include button($grey, large);
    }
  }
  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08);
  }
}
</style>
