<template>
<Modal
  :title="$t('account_required.title')"
  :submitable="false"
  class="account-required-modal"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="account-required-content">
      {{ $t('account_required.text') }}
      <SocialButton socialType="facebook" @click="handleSocial" />
      <SocialButton socialType="google" @click="handleSocial" />
      <div v-if="socialError" class="error-message">
        {{ socialError }}
      </div>
      <a class="account-required-email" @click="goto('signup')">
        {{ $t('account_required.email_signup') }}
      </a>
      <div class="account-required-login">
        {{ $t('account_required.login') }}<span @click="goto('login')">{{ $t('login') }}</span>
      </div>
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import SocialButton from '@/components/widget/SocialButton.vue';
import { SET_LOGIN_REDIRECT } from '@/store/actions';
import Social from '@/mixins/Social';

export default {
  name: 'account-required-modal',
  components: {
    Modal,
    SocialButton,
  },
  mixins: [Social],
  props: {
    fromProject: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      socialError: null,
    };
  },
  computed: {
    returnPath() {
      if(this.fromProject) {
        return `${this.$route.path}#rewards`;
      }
      return this.$route.fullPath;
    },
  },
  methods: {
    goto(page) {
      this.$store.dispatch(SET_LOGIN_REDIRECT, this.returnPath);
      this.$router.push({ name: page });
    },
    async handleSocial(type) {
      await this.$store.dispatch(SET_LOGIN_REDIRECT, this.returnPath);
      this.socialLoginSignup(type);
    },
    // If social auth succeeds immediately, we're good.
    redirectUser() {
      this.$emit('close-modal');
    },
  },
  destroyed() {
    document.body.classList.remove('noscroll');
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.account-required-modal.modal-outer .modal-inner {
  text-align: center;
  max-width: 600px;
  padding: 8px 0 40px;
  .modal-top .cancel {
    top: 8px;
    right: 56px;
  }
  .error-message {
    @include h5;
    color: $main-red;
  }
  .account-required-content {
    @include p_large;
    box-sizing: border-box;
    font-weight: 400;

    .social-btn {
      width: 70%;
      margin: 0 auto 16px;
    }
    .facebook {
      margin-top: 32px;
    }

    .account-required-email {
      margin-top: 24px;
      text-decoration: underline;
      cursor: pointer;
      display: block;
    }
    .account-required-login {
      @include h4;
      margin-top: 48px;
      span {
        margin-left: 8px;
        color: $main-blue;
        cursor: pointer;
      }
    }
  }
}
</style>
