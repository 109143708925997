<template>
<svg
  viewBox="0 0 12 12"
  width="12"
  height="12"
  xmlns="http://www.w3.org/2000/svg"
  @click="clickable && $emit('click')"
>
  <path
    clip-rule="evenodd"
    d="M6.75 0H5.25V5.25H0V6.75H5.25V12H6.75V6.75H12V5.25H6.75V0Z"
    :fill="color"
  />
</svg>
</template>

<script>

export default {
  name: 'plus',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
