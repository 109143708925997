<template>
<div class="faq-item">
  <div class="faq-accordion-wrap">
    <div class="faq-accordion" @click="toggleContent">
      <div class="faq-title">
        {{ faq.question }}
      </div>
      <div v-if="edit" class="faq-edit" @click="$emit('edit', $event)">
        {{ $t('edit') }}
      </div>
      <div v-if="edit" class="faq-delete" @click="$emit('delete', $event)">
        {{ $t('delete') }}
      </div>
      <!-- eslint-disable -->
      <svg width="21" height="12" viewBox="0 0 23 14" :class="{ 'opened': isOpened, edit }">
        <g>
          <line x1="1" y1="1" x2="11.5" y2="12" class="svg_line" stroke-linecap="round" />
          <line x1="11.5" y1="12" x2="22" y2="1" class="svg_line" stroke-linecap="round" />
        </g>
      </svg>
      <!-- eslint-enable -->
    </div>
    <transition
      name="animate-height"
      @enter="enter"
    >
      <div
        v-if="isOpened"
        :style="{ 'max-height': panelHeight, height: panelHeight }"
        class="panel"
        v-html="answer"
      />
    </transition>
    <div ref="hiddenPanel" class="pseudo-panel" v-html="answer" />
  </div>
</div>
</template>

<script>

export default {
  name: 'faq-accordion',
  props: {
    faq: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpened: false,
      panelHeight: 0,
    };
  },
  computed: {
    answer() {
      let ans = (this.faq || {}).answer || '';
      ans = ans.replace(/(?:\r\n|\r|\n)/g, '<br>');
      ans = ans.replace(/\[(.*?)\]\((.*?)\)/gm, '<a target="_blank" href="$2">$1</a>');
      return this.$sanitize(ans);
    },
  },
  methods: {
    toggleContent() {
      this.isOpened = !this.isOpened;
    },
    enter(_panel) {
      const height = parseInt(getComputedStyle(this.$refs.hiddenPanel).height);
      this.panelHeight = `${height}px`;
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.faq-item {
  width: 100%;
  border-bottom: 1px solid $border-light;
  padding: 16px 0;
  .faq-accordion-wrap {
    position: relative;
    .faq-accordion {
      cursor: pointer;
      display: flex;
      align-items: center;
      .faq-title {
        @include h4;
        margin-right: 32px;
      }
      svg {
        flex-shrink: 0;
        transition: transform $speed2 ease-in-out;
        position: relative;
        margin-left: auto;
        top: -1px;
        &.opened {
          transform: rotate(180deg);
        }
        &.edit {
          margin-left: 0;
        }
      }
    }
    .faq-edit, .faq-delete {
      @include button_small_text;
      text-transform: uppercase;
      color: $main-blue;
      margin-right: 16px;
    }
    .faq-edit {
      margin-left: auto;
    }
    .pseudo-panel {
      position: absolute;
      visibility: hidden;
      width: 100%;
    }
    .panel, .pseudo-panel {
      font-family: $font-normal;
      font-size: 18px;
      word-break: break-word;
      transition: all $speed3;
      overflow: hidden;
      padding-top: 4px;
      a {
        color: $main-blue;
      }
    }
    .animate-height-enter, .animate-height-leave-to {
      max-height: 0 !important;
    }
  }
}
</style>
